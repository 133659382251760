<!--Mapa que se muestra en el detalle del evento-->
<template>
    <div :class="(!flat===true)?'map-container':'flat-map'">
        <gmap-map :center="center" :zoom="16" :options="mapOptions" class="map-zone">
            <GmapMarker :position="marker" :clickable="true" :draggable="false" @click="center=marker"/>
        </gmap-map>
    </div>
</template>
<script>
import { mapStyles } from '@/constants/mapStyles'
export default {
    props:{
        flat: { type: Boolean, required: false },
        position: { type: Object, required: true },
        dark: { type:Boolean, required:false }
    },
    data(){
        return {
            center: {},
            marker: {},
            mapOptions: {
                mapTypeControl: false,
                styles: mapStyles.silver
            }
        }
    },
    mounted(){
        let { lat, lng } = this.position;
        lat = Number(lat);
        lng = Number(lng);
        this.marker = { lat, lng };
        this.center = { lat, lng };
        if(this.dark)
            this.mapOptions.styles = mapStyles.dark
    }
}
</script>
<style scoped>
    .map-container{
        overflow:hidden;
        padding-bottom:30%;
        position:relative;
        height: 0;
    }
    .flat-map{
        overflow:hidden;
        padding-bottom:25%;
        position:relative;
        height: 0;
    }
    .map-zone{
        left:0;
        top:0;
        height:100%;
        width:100%;
        position:absolute;
    }
    @media (max-width: 599px) {
        .map-container{
            padding-bottom:65%;
        }
        .flat-map{
            padding-bottom:60%;
        }
    }

    @media (min-width: 600px) and (max-width: 959px)  {
        .map-container{
            padding-bottom:40%;
        }
        .flat-map{
            padding-bottom:35%;
        }
    }

    @media (min-width: 960px) and (max-width: 1263px)  {
        .map-container{
            padding-bottom:40%;
        }
        .flat-map{
            padding-bottom:35%;
        }
    }

    @media (min-width: 1264px) and (max-width: 1903px)  {
        .map-container{
            padding-bottom:40%;
        }
        .flat-map{
            padding-bottom:25%;
        }
    }
</style>