<!--Sección para mostrar el micrositio de las organizaciones-->
<template>
  <v-container v-if="!loaderVisible && micrositeData" fluid>
    <v-row
      class="px-3 px-md-1 pt-3 pt-md-3"
      :class="!micrositeData.published ? 'grayscale-disabled-element' : ''"
    >
      <v-col cols="12" sm="10" offset-sm="1">
        <v-img
          width="auto"
          v-if="micrositeData.banner && micrositeData.banner != ''"
          :src="micrositeData.banner"
          class="banner-img rounded-xl"
        ></v-img>
      </v-col>
    </v-row>
    <v-row
      :class="[
        $vuetify.breakpoint.mdAndUp ? 'py-5' : 'px-2',
        !micrositeData.published ? 'grayscale-disabled-element' : '',
      ]"
    >
      <v-col cols="12" sm="10" offset-sm="1">
        <v-row
          class="py-5 mx-1"
          v-bind:style="`background-color: ${primaryColor}; border-radius: 20px`"
        >
          <v-col cols="12" md="8" lg="9" class="px-5 px-md-12">
            <p
              v-if="micrositeData.dateConfiguration"
              class="mb-1 font-weight-medium subtitle-1"
              :style="`color:${secondaryColor}`"
            >
              {{ dateEvent() }}
            </p>
            <p
              class="mb-1 font-weight-black display-1"
              :style="`color:${secondaryColor}`"
            >
              {{ micrositeData.name }}
            </p>
            <p
              class="mb-1 font-weight-medium subtitle-1"
              :style="`color:${secondaryColor}`"
            >
              {{ micrositeData.type }} / {{ micrositeData.category }}
            </p>
            <p
              class="mb-1 font-weight-medium subtitle-1"
              :style="`color:${secondaryColor}`"
            >
              <v-icon :color="secondaryColor">
                {{
                  micrositeData.eventModality == "PRESENTIAL"
                    ? "mdi-google-maps"
                    : micrositeData.eventModality == "VIRTUAL"
                    ? "mdi-earth"
                    : "mdi-merge"
                }}
              </v-icon>
              {{ eventModalities[micrositeData.eventModality] }}
            </p>
          </v-col>
          <v-col cols="12" md="4" lg="3" class="d-flex align-center">
            <v-row class="d-flex justify-end">
              <!--<v-col v-if="!micrositeData.published" cols="12" class="pa-0 d-flex justify-center">
                <p class="font-weight-bold subtitle-1" :style="`color:${secondaryColor}`">
                  El evento no está disponible
                </p>
              </v-col>-->
              <v-col cols="12" class="pa-0 d-flex justify-center">
                <v-btn
                  :color="buttonColor"
                  rounded
                  :disabled="!micrositeData.published"
                  @click="goToCheckout()"
                >
                  <span
                    class="font-weight-bold"
                    :style="`color:${
                      micrositeData.published ? textButtonColor : '#ffffff'
                    }`"
                  >
                    {{
                      micrositeData.published
                        ? "Adquirir acceso"
                        : "Evento no disponible"
                    }}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!--<v-row v-if="!micrositeData.published" :class="$vuetify.breakpoint.mdAndUp?'py-5':'px-2'">
      <v-col cols="12">
        <p class="display-1 font-weight-bold text-center mb-0 mt-3 secondary--text">Evento no disponible</p>
      </v-col>
    </v-row>-->
    <v-row
      :class="[
        $vuetify.breakpoint.mdAndUp ? 'mt-16' : 'mt-5 mx-1',
        !micrositeData.published ? 'grayscale-disabled-element' : '',
      ]"
    >
      <v-col cols="12" sm="10" offset-sm="1">
        <v-row>
          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="12">
                <p
                  class="headline font-weight-bold"
                  v-bind:style="`color: ${primaryColor}`"
                >
                  Detalles del evento
                </p>
                <!--<p class="subtitle-1">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                  natoque penatibus et magnis dis parturient montes, nascetur
                  ridiculus mus. Donec quam felis, ultricies nec, pellentesque
                  eu, pretium quis, sem. Nulla consequat massa quis enim. Donec
                  pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.
                </p>
                <ul>
                  <li class="subtitle-1" type="disc">
                    In enim justo, rhoncus ut, imperdiet a, venenatis vitae,
                    justo. Nullam dictum felis eu pede mollis pretium.
                  </li>
                  <li class="subtitle-1" type="disc">
                    Integer tincidunt. Cras dapibus.
                  </li>
                  <li class="subtitle-1" type="disc">
                    Vivamus elementum semper nisi. Aenean vulputate eleifend
                    tellus.
                  </li>
                </ul>-->
                <span
                  v-html="this.micrositeData.description"
                  class="event-custom-content"
                ></span>
              </v-col>
              <!--<v-col cols="12" class="mb-10">
                <v-img class="rounded-xl" max-width="560" height="auto" src="https://picsum.photos/600/1000?" contain></v-img>
              </v-col>-->
              <v-col class="mb-10" cols="12">
                <v-carousel
                  height="auto"
                  v-if="flyerArray.length > 1"
                  :show-arrows="showCarouselArrows"
                  cycle
                  hide-delimiters
                  next-icon="mdi-chevron-right"
                  prev-icon="mdi-chevron-left"
                >
                  <v-carousel-item
                    v-for="(flyer, index) in flyerArray"
                    :key="index"
                  >
                    <v-sheet class="mx-5" rounded="lg" color="primary">
                      <v-img
                        background-size: cover
                        :src="flyer"
                        class="rounded-xl"
                        height="auto"
                        max-width="3500"
                      ></v-img>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>

                <v-img
                  v-else
                  :src="flyerArray[0]"
                  class="rounded-xl"
                  height="auto"
                  max-width="800"
                ></v-img>
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-center"
                v-if="
                  micrositeData.youtubeVideo && micrositeData.youtubeVideo != ''
                "
              >
                <h1 class="headline font-weight-bold mb-5">
                  Video publicitario
                </h1>
              </v-col>
              <v-col
                cols="12"
                style="flex-wrap: wrap"
                class="mb-10 mb-md-10 d-flex justify-center"
                data-aos="fade-right"
                data-aos-duration="400"
                v-if="
                  micrositeData.youtubeVideo && micrositeData.youtubeVideo != ''
                "
              >
                <iframe
                  class="rounded-xl"
                  width="560"
                  height="315"
                  :src="youtubeCut(micrositeData.youtubeVideo)"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                >
                </iframe>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="4">
            <v-row>
              <v-col cols="12">
                <v-card
                  class="px-3 pt-6 pb-2"
                  rounded="xl"
                  elevation="0"
                  v-bind:style="`border: 1px solid ${primaryColor}`"
                >
                  <v-row class="px-2">
                    <v-col cols="12" class="pb-0">
                      <p
                        class="headline font-weight-bold"
                        v-bind:style="`color: ${primaryColor}`"
                      >
                        Amenidades
                      </p>
                    </v-col>
                    <v-col cols="12">
                      <v-chip
                        class="ma-2"
                        outlined
                        v-for="(item, index) in filterAmenities(
                          micrositeData.amenities
                        )"
                        :key="index"
                      >
                        <span class="d-flex align-center pa-2">
                          <v-icon left :color="buttonColor">
                            {{ amenities[item] }}
                          </v-icon>
                          {{ item }}
                        </span>
                      </v-chip>
                      <!--<v-chip class="ma-2" outlined>
                        <v-icon left> mdi-room-service </v-icon>
                        Comida
                      </v-chip>
                      <v-chip class="ma-2" outlined>
                        <v-icon left> mdi-license </v-icon>
                        Constancia
                      </v-chip>
                      <v-chip class="ma-2" outlined>
                        <v-icon left> mdi-wifi </v-icon>
                        Wi-Fi
                      </v-chip>
                      <v-chip class="ma-2" outlined>
                        <v-icon left> mdi-room-service </v-icon>
                        Comida
                      </v-chip>
                      <v-chip class="ma-2" outlined>
                        <v-icon left> mdi-license </v-icon>
                        Constancia
                      </v-chip>-->
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card
                  class="px-3 pt-6 pb-2"
                  rounded="xl"
                  elevation="0"
                  v-bind:style="`border: 1px solid ${primaryColor}`"
                >
                  <v-row class="px-2">
                    <v-col cols="12" class="pb-0">
                      <p
                        class="headline font-weight-bold"
                        v-bind:style="`color: ${primaryColor}`"
                      >
                        Organizador
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="px-2">
                    <v-col
                      v-if="
                        micrositeData.organization &&
                        micrositeData.organization.profilePicture &&
                        micrositeData.organization.profilePicture != ''
                      "
                      cols="12"
                      class="d-flex justify-center py-0"
                    >
                      <v-img
                        class="rounded-xl"
                        max-width="130"
                        height="auto"
                        :src="micrositeData.organization.profilePicture"
                      ></v-img>
                    </v-col>
                    <v-col v-if="micrositeData.organization" cols="12">
                      <p class="mb-0 font-weight-bold subtitle-1 text-center">
                        {{ micrositeData.organization.company }}
                      </p>
                      <p class="text-center mb-0">
                        {{ micrositeData.emailContact }}
                      </p>
                    </v-col>
                    <v-col
                      v-if="this.micrositeData.socialMedia"
                      cols="12"
                      class="mb-4"
                    >
                      <v-row class="d-flex justify-center">
                        <v-btn
                          v-if="this.micrositeData.socialMedia.facebook != ''"
                          icon
                          :color="buttonColor"
                          :href="this.micrositeData.socialMedia.facebook"
                          target="_blank"
                        >
                          <v-icon>mdi-facebook</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="this.micrositeData.socialMedia.instagram != ''"
                          icon
                          :color="buttonColor"
                          :href="this.micrositeData.socialMedia.instagram"
                          target="_blank"
                        >
                          <v-icon>mdi-instagram</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="this.micrositeData.socialMedia.twitter != ''"
                          icon
                          :color="buttonColor"
                          :href="this.micrositeData.socialMedia.twitter"
                          target="_blank"
                        >
                          <v-icon>mdi-twitter</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="this.micrositeData.socialMedia.linkedin != ''"
                          icon
                          :color="buttonColor"
                          :href="this.micrositeData.socialMedia.linkedin"
                          target="_blank"
                        >
                          <v-icon>mdi-linkedin</v-icon>
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col
                v-if="
                  this.micrositeData.sponsors &&
                  this.micrositeData.sponsors.length > 0
                "
                cols="12"
              >
                <v-card
                  class="px-3 pt-6 pb-2"
                  rounded="xl"
                  elevation="0"
                  v-bind:style="`border: 1px solid ${primaryColor}`"
                >
                  <v-row class="px-2">
                    <v-col cols="12" class="pb-0">
                      <p
                        class="headline font-weight-bold"
                        v-bind:style="`color: ${primaryColor}`"
                      >
                        Patrocinadores
                      </p>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <v-row justify="center">
                        <v-col
                          cols="6"
                          md="4"
                          xl="3"
                          class="d-flex justify-center pa-5"
                          v-for="sponsor in this.micrositeData.sponsors"
                          :key="sponsor._id"
                        >
                          <v-img
                            class="rounded-lg align-self-center sponsor-img"
                            @click="goToPage(sponsor.url)"
                            :src="sponsor.logo"
                            style="cursor: pointer"
                            max-width="70"
                            height="auto"
                          ></v-img>
                        </v-col>
                        <!--<v-col cols="6" md="4" xl="3" class="pa-5">
                          <v-img src="@/assets/images/landing/organizerDomestika.png"></v-img>
                        </v-col>
                        <v-col cols="6" md="4" xl="3">
                          <v-img src="@/assets/images/landing/de-la-salle.png"></v-img>
                        </v-col>
                        <v-col cols="6" md="4" xl="3">
                          <v-img src="@/assets/images/landing/innovacion-salle.png"></v-img>
                        </v-col>
                        <v-col cols="6" md="4" xl="3">
                            <v-img src="@/assets/images/landing/de-la-salle.png"></v-img>
                        </v-col>
                        <v-col cols="6" md="4" xl="3">
                          <v-img class="rounded-xl" src="@/assets/images/landing/innovacion-salle.png"></v-img>
                        </v-col>
                        <v-col cols="6" md="4" xl="3">
                          <v-img class="rounded-xl" src="@/assets/images/landing/organizerDomestika.png"></v-img>
                        </v-col>-->
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="googleMapsPosition.hasOwnProperty('lat')"
      class="mt-5 mt-md-10"
      :class="!micrositeData.published ? 'grayscale-disabled-element' : ''"
    >
      <v-col cols="12" sm="10" offset-sm="1" class="px-6 px-md-2">
        <map-card :position="googleMapsPosition" class="rounded-xl"></map-card>
      </v-col>
    </v-row>
    <v-row
      v-if="this.micrositeData.faqs && this.micrositeData.faqs.length > 0"
      :class="!micrositeData.published ? 'grayscale-disabled-element' : ''"
    >
      <v-col
        cols="12"
        sm="10"
        offset-sm="1"
        class="px-6 px-md-2 pb-0 pt-5 d-flex align-center"
      >
        <p
          class="headline font-weight-bold mb-1 mt-4"
          v-bind:style="`color: ${primaryColor}`"
        >
          Preguntas frecuentes
        </p>
        <v-tooltip top max-width="350px">
          <template v-slot:activator="{ on }">
            <v-icon :color="buttonColor" dark v-on="on" class="ml-2 mt-4"
              >mdi-help-circle</v-icon
            >
          </template>
          <span
            >Si estás interesado en este evento, aquí apartado se responden las
            preguntas frecuentes que los clientes tienen sobre el evento que
            ofrece la organización.</span
          >
        </v-tooltip>
      </v-col>
      <v-col cols="12" sm="10" offset-sm="1" class="px-6 px-md-2">
        <v-expansion-panels multiple flat>
          <v-expansion-panel
            v-for="item in this.micrositeData.faqs"
            :key="item.id"
            class="rounded-xl my-2"
            style="background: #f0f0f0"
          >
            <v-expansion-panel-header>
              <span class="subtitle-1 font-weight-bold">{{
                item.question
              }}</span>
              <template v-slot:actions>
                <v-icon :color="buttonColor"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ item.answer }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row
      class="my-10"
      :class="!micrositeData.published ? 'grayscale-disabled-element' : ''"
    >
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <v-img
          max-width="150"
          height="auto"
          src="@/assets/images/weChamber/poweredby.svg"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
  <!--Mostrar este apartado si no existe el evento que se quiere mostrar-->
  <v-container v-else-if="!loaderVisible && !micrositeData" fluid>
    <v-row justify="center" align="center">
      <v-col cols="12">
        <v-row justify="center" align="center" class="mt-10 text-center">
          <v-col cols="12" sm="4" lg="5">
            <v-row justify="center">
              <v-img
                src="@/assets/images/weChamber/imagotype-WeChamber.jpg"
                width="100%"
                height="auto"
                contain
              ></v-img>
            </v-row>
          </v-col>
          <v-col cols="12" class="mt-10">
            <h1 class="display-1 font-weight-bold">
              ¡El evento que buscas no se encontró!
            </h1>
          </v-col>
          <v-col cols="12">
            <h1 class="display-12">404</h1>
          </v-col>
          <v-col cols="12">
            <v-btn
              class="font-weight-bold"
              x-large
              color="secondary"
              rounded
              to="/"
            >
              Ir al inicio
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import MapCard from "@/components/landing/map-card";
import { mapState, mapMutations, mapActions } from "vuex";
import { Base64 } from "js-base64";
export default {
  components: {
    MapCard,
  },
  computed: {
    ...mapState("event", ["eventSelected", "micrositeData"]),
    ...mapState("loader", ["loaderVisible"]),
    ...mapState("user", ["user"]),
    showCarouselArrows() {
      return this.flyerArray.length > 1;
    },
  },
  data() {
    return {
      flyerArray: [],
      eventId: "",
      eventModalities: {
        HYBRID: "Híbrido",
        PRESENTIAL: "Presencial",
        VIRTUAL: "Virtual",
      },
      amenities: {
        "Estacionamiento": "mdi-car-outline",
        "Coffee Break": "mdi-coffee-outline",
        "Alimentos": "mdi-silverware-fork-knife",
        "Aire Acondicionado": "mdi-air-conditioner",
        "Constancia": "mdi-card-bulleted-outline",
        "Cóctel": "mdi-glass-cocktail",
        "Valet Parking": "mdi-car-hatchback",
        "Zona Comercial": "mdi-store",
        "Hospedaje": "mdi-office-building-outline",
      },
      socialMediaIcons: {
        facebook: "mdi-facebook",
        twitter: "mdi-twitter",
        linkedin: "mdi-linkedin",
        instagram: "mdi-instagram",
      },
      primaryColor: "#FE484B",
      secondaryColor: "",
      buttonColor: "",
      textButtonColor: "",
      googleMapsPosition: {},
    };
  },
  methods: {
    ...mapActions("event", ["fetchMicrositeData", "fetchEventById"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    goToPage(tab) {
      if (tab != "") window.open(tab);
    },
    filterAmenities(amenities) {
    return amenities.filter(item => {
      const words = item.split(' ');
      if (words.length > 1 && words[1][0] === words[1][0].toLowerCase()) {
        return false;
      }
      return true;
    });
  },
    goToCheckout() {
      if (
        this.user &&
        (this.user.role === "MEMBER" || this.user.role === "MEMBERSHIP_USER")
      ) {
        this.$router.push(`/member/eventCheckout/${this.eventId}`);
      } else {
        this.$router.push({
          path: `/checkout/${this.eventId}`,
          query: { organization: this.micrositeData.organization.company },
        });
      }
    },
    dateEvent() {
     const  {
              startDate = '00:00',
              endDate = '00:00',
              startTime,
              endTime
            } = this.micrositeData.dateConfiguration
      let startDateFormatted = this.dateFormatted(
        `${startDate.substring( 0, 10 )} `.replace(/-/g, "/")
      );

      let endDateFormatted = this.dateFormatted(
        `${endDate.substring( 0, 10 ) } `.replace(/-/g, "/")
      );

      if (startDateFormatted == endDateFormatted) {
        return `${startDateFormatted} ${startTime} - ${endTime}`;
      } else {
        return `${startDateFormatted + ' ' + startTime} - ${endDateFormatted + ' ' + endTime}`;
      }
    },
    dateFormatted(eventDate) {
      const date = new Date(eventDate);
      return date.toLocaleDateString("es-MX");
    },
    youtubeCut(youtube) {
      let youtubeId = "";

      // Comprobar si la URL es de YouTube Shorts
      if (youtube.startsWith("https://www.youtube.com/shorts/")) {
        // Extraer el ID del video de Shorts
        const shortsId = youtube.split("https://www.youtube.com/shorts/")[1];

        // Construir la URL completa de YouTube
        youtube = `https://www.youtube.com/watch?v=${shortsId}`;
      }

      // Extraer el ID del video de YouTube
      if (youtube.startsWith("https://youtu.be/")) {
        youtubeId = youtube.split("https://youtu.be/")[1];
      } else {
        if (youtube.includes("?v=")) {
          youtubeId = youtube.split("?v=")[1];
        } else if (youtube.includes("&v=")) {
          youtubeId = youtube.split("&v=")[1];
        }
      }
      youtubeId = youtubeId.split("&")[0];

      // Construir la URL embebida de YouTube
      return `https://www.youtube.com/embed/${youtubeId}`;
    },
  },
  async mounted() {
    this.loading();

    this.eventId = this.$route.params.eventId;
    let response = await this.fetchMicrositeData(this.eventId);
    console.log(response);
    console.log(this.micrositeData);

    // Comprobar si existe el evento
    if (this.micrositeData) {
      this.primaryColor =
        this.micrositeData.micrositeConfiguration.primaryColor;
      this.secondaryColor =
        this.micrositeData.micrositeConfiguration.secondaryColor;
      this.buttonColor = this.micrositeData.micrositeConfiguration.buttonColor;
      this.textButtonColor =
        this.micrositeData.micrositeConfiguration.textButtonColor;
      console.log("Datos del evento");
      console.log(response);
      console.log(this.micrositeData);

      if (this.micrositeData.description != "") {
        this.micrositeData.description = Base64.decode(
          this.micrositeData.description
        );
      }

      if (
        this.micrositeData.presentialEventConfiguration &&
        this.micrositeData.presentialEventConfiguration.latitude
      ) {
        this.googleMapsPosition.lat =
          this.micrositeData.presentialEventConfiguration.latitude;
        this.googleMapsPosition.lng =
          this.micrositeData.presentialEventConfiguration.longitude;
      }

      if (this.micrositeData.flyer) {
        this.flyerArray = this.micrositeData.flyer.split(",");
        console.log(this.flyerArray);
      }
    }

    this.loaded();
  },
};
</script>
<style scoped>
.sponsor-img {
  filter: grayscale(1);
  height: auto;
  transition: 0.2s all ease-in-out;
  border-radius: 15px;
  justify-self: center;
}

.grayscale-disabled-element {
  filter: grayscale(1);
}

.loader-container {
  height: 95vh;
}
.banner-img {
  width: 100%;
  height: auto;
}
.main-information-container {
  background-color: #fe484b;
}
event-custom-content >>> h1,
h2,
h3,
h4 {
  font-weight: 700 !important;
}
.event-custom-content >>> blockquote {
  border-left: 4px solid #ccc !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  padding-left: 16px !important;
}
@media (min-width: 400px) and (max-width: 599px) {
  .event-custom-content >>> h1 {
    font-size: 1.05em !important;
  }
  .event-custom-content >>> h2 {
    font-size: 1em !important;
  }
  .event-custom-content >>> h3 {
    font-size: 0.95em !important;
  }
}
@media (min-width: 600px) and (max-width: 959px) {
  .event-custom-content >>> h1 {
    font-size: 1em !important;
  }
  .event-custom-content >>> h2 {
    font-size: 0.95em !important;
  }
  .event-custom-content >>> h3 {
    font-size: 0.9em !important;
  }
}
@media (min-width: 960px) and (max-width: 1263px) {
  .event-custom-content >>> h1 {
    font-size: 1.2em !important;
  }
  .event-custom-content >>> h2 {
    font-size: 1em !important;
  }
  .event-custom-content >>> h3 {
    font-size: 0.9em !important;
  }
}
@media (min-width: 1264px) and (max-width: 1599px) {
  .event-custom-content >>> h1 {
    font-size: 1.28em !important;
  }
  .event-custom-content >>> h2 {
    font-size: 1.2em !important;
  }
  .event-custom-content >>> h3 {
    font-size: 1em !important;
  }
}
@media (min-width: 1599px) and (max-width: 1903px) {
  .event-custom-content >>> h1 {
    font-size: 1.5em !important;
  }
  .event-custom-content >>> h2 {
    font-size: 1.2em !important;
  }
  .event-custom-content >>> h3 {
    font-size: 1.1em !important;
  }
}
@media (min-width: 1904px) {
  .event-custom-content >>> h1 {
    font-size: 1.5em !important;
  }
  .event-custom-content >>> h2 {
    font-size: 1.2em !important;
  }
  .event-custom-content >>> h3 {
    font-size: 1.1em !important;
  }
}
.jumbotron__wrapper{
  max-height: 95vh !important;
}
.jumbotron__wrapper img{
  max-height: 100% !important;
}
</style>